
.react-datepicker {
  width: 100% !important;
  min-width: 100% !important;
 
  font-family: Stolzl !important;
  border: 1px solid #00b1dc80 !important;
  justify-content: center !important;
}

.react-datepicker__header {
  width: 100% !important;
  padding: 0px !important;
  text-align: center !important;
  background-color: white !important;

}


.react-datepicker__current-month {
  font-size: 12px !important;
  font-weight: 200 !important;
  color:#00b1dc !important;
  margin-top: 10px !important;
  height: 28px !important;
  min-width: 100% !important;

}

.react-datepicker__month-container {
  width:100% !important;
  justify-content: center !important;
  min-width: 100% !important;

}

.react-datepicker__day-names {
  display: none !important;
  width: calc(100% + 2px) !important;
  margin-left: -1px !important;
  height: 36px !important;
  background-color: #00b1dc !important;
}

.react-datepicker__day-name {
  color: white !important;
  
}

.react-datepicker__month {
  width: 100% !important;
  justify-content: space-between !important;
  margin: 0px !important;
  min-width: 100% !important;
  
}

.react-datepicker__week {
  min-width: 100% !important;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: 100% !important;
  justify-items: center; 

}

.react-datepicker__day {
  min-width: 100%;
}



.react-datepicker__day--in-range {
  
  background-size: cover;
  background-color:#00b0dc83 !important;
  color: black !important;

  border-radius: 0px;
}

.react-datepicker__day--in-range:hover {
  border-radius: 0px;
}

.react-datepicker__day--range-start {
  border-radius: 10px 0px 0px 10px !important;
}

.react-datepicker__day--range-end {
  border-radius: 0px 10px 10px 0px !important;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
  border-radius: 10px 10px 10px 10px !important;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end:hover {
  border-radius: 10px 10px 10px 10px !important;
}

.react-datepicker__day--selected:not(.react-datepicker__day--range-start) {
  border-radius: 10px 10px 10px 10px;
  background-size: cover;
  background-color:#00b0dc83 !important;
  color: black !important;
}

.react-datepicker {
  width: 100% !important;
  min-width: 100% !important;
 
  font-family: Stolzl !important;
  border: 1px solid #00b1dc80 !important;
  justify-content: center !important;
}

.react-datepicker__header {
  width: 100% !important;
  padding: 0px !important;
  text-align: center !important;
  background-color: white !important;

}


.react-datepicker__current-month {
  font-size: 12px !important;
  font-weight: 200 !important;
  color:#00b1dc !important;
  margin-top: 10px !important;
  height: 28px !important;
  min-width: 100% !important;

}

.react-datepicker__month-container {
  width:100% !important;
  justify-content: center !important;
  min-width: 100% !important;

}

.react-datepicker__day-names {
  display: none !important;
  width: calc(100% + 2px) !important;
  margin-left: -1px !important;
  height: 36px !important;
  background-color: #00b1dc !important;
}

.react-datepicker__day-name {
  color: white !important;
  
}

.react-datepicker__month {
  width: 100% !important;
  justify-content: space-between !important;
  margin: 0px !important;
  min-width: 100% !important;
  
}

.react-datepicker__week {
  min-width: 100% !important;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: 100% !important;
  justify-items: center; 

}

.react-datepicker__day {
  min-width: 100%;
}



.react-datepicker__day--in-range {
  
  background-size: cover;
  background-color:#00b0dc83 !important;
  color: black !important;

  border-radius: 0px;
}

.react-datepicker__day--in-range:hover {
  border-radius: 0px;
}

.react-datepicker__day--range-start {
  border-radius: 10px 0px 0px 10px !important;
}

.react-datepicker__day--range-end {
  border-radius: 0px 10px 10px 0px !important;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
  border-radius: 10px 10px 10px 10px !important;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end:hover {
  border-radius: 10px 10px 10px 10px !important;
}

.react-datepicker__day--selected:not(.react-datepicker__day--range-start) {
  border-radius: 10px 10px 10px 10px;
  background-size: cover;
  background-color:#00b0dc83 !important;
  color: black !important;
}
