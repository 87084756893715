select {
  outline: none;
}
input {
  outline: none;
}

.css-tj5bde-Svg {
  width: 30px;
  height: 30px;
}

.css-7ehtgf-indicatorContainer {
  padding: 8px 14px 8px 14px;
}

/* SCROLLBAR */

@media (min-width: 600px) {
  * {
    scrollbar-color: #00b1dc white;
    scrollbar-width: thin;
  }
}

/* Hide */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* width */
@media (min-width: 600px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 5px var(--gray);*/
  border-radius: 2px;
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b1dc;
  border-radius: 2px;
}

.loader {
  border: 10px solid #e6ff00;
  border-top: 10px solid #00b1dc;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
